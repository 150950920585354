<!-- eslint-disable max-len -->
<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.625"
      y="0.625"
      width="16.75"
      height="16.75"
      rx="8.375"
      fill="white"
    />
    <rect
      x="0.625"
      y="0.625"
      width="16.75"
      height="16.75"
      rx="8.375"
      stroke="white"
      stroke-width="0.75"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM11.4 6.6C11.4 7.92548 10.3255 9 9 9C7.67452 9 6.6 7.92548 6.6 6.6C6.6 5.27452 7.67452 4.2 9 4.2C10.3255 4.2 11.4 5.27452 11.4 6.6ZM8.99999 15.8C10.4272 15.8 11.7518 15.3603 12.8455 14.609C13.3286 14.2771 13.5351 13.645 13.2542 13.1306C12.672 12.0642 11.4722 11.4 8.99995 11.4C6.52775 11.4 5.32797 12.0642 4.74571 13.1305C4.46484 13.6449 4.67128 14.2771 5.15437 14.6089C6.24815 15.3603 7.57272 15.8 8.99999 15.8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup></script>
