<!-- eslint-disable max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.0028 14.2682C1.84802 9.29978 2.11349 8.23891 2.91026 6.64566C3.72576 5.01496 5.06774 3.40766 6.27978 2.60996C7.99263 1.48266 11.3316 0.770022 13.5798 1.05193C14.0626 1.11245 14.4767 1.18119 14.5002 1.20467C14.5906 1.29508 13.5027 2.24102 12.8059 2.67784C12.1422 3.09392 11.3976 3.41888 9.25434 4.22775C6.01456 5.45046 3.41056 8.48922 1.45569 13.3285C1.17729 14.0176 0.973495 14.4405 1.0028 14.2682ZM4.07133 11.4259C6.68468 9.36764 10.3052 8.75751 12.9801 9.92466L13.4325 10.122L13.0348 10.2935C12.7624 10.4108 12.0878 10.4964 10.8942 10.5649C7.2777 10.7726 5.6997 11.0124 4.39428 11.5526C4.02426 11.7058 3.65104 11.8574 3.5649 11.8896C3.47875 11.9218 3.70665 11.7132 4.07133 11.4259ZM5.23559 8.74143C5.8567 8.09623 6.98038 7.21861 7.75814 6.77125C8.02565 6.61738 8.68349 6.34169 9.22 6.1586C10.0947 5.86009 10.3249 5.82491 11.4485 5.81801C12.1883 5.81347 12.9367 5.86899 13.2758 5.95358C13.967 6.12599 14.8421 6.48404 14.8421 6.59441C14.8421 6.71459 14.2202 6.98527 13.5275 7.16665C13.1881 7.25551 12.0176 7.44666 10.9264 7.59143C8.50419 7.91279 7.2647 8.21001 5.96653 8.78077C5.42094 9.02064 4.90407 9.24415 4.81792 9.27745C4.73178 9.31075 4.91973 9.06954 5.23559 8.74143Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup>
</script>
